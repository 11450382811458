import * as constants from './constant'

const INIT_STATE = {
    loading: false,
};

const Reducer = (state = INIT_STATE, action) => {
    switch (action.type) {        
        case constants.Cities_Get:
        case constants.Cities_Get_BY_NAME:
        case constants.Cities_ALL_LIST_WITH_PARENT:
        case constants.Cities_Get_COUNTRY_CODE:
            return { ...state,  loading: true };
        case constants.Cities_Get_FAILED:
        case constants.Cities_ALL_LIST_WITH_PARENT_FAILED:
        case constants.Cities_Get_BY_NAME_FAILED:
        case constants.Cities_Get_COUNTRY_CODE_FAILED:
            return { ...state, loading: false, error: action.payload };
        case constants.Cities_ALL_LIST_WITH_PARENT_SUCCESS:
            return { ...state, ListCityWithParent: action.payload, loading: false, error: null };
        case constants.Cities_Get_SUCCESS:
            return { ...state, ListCity: action.payload, loading: false, error: null };
        case constants.Cities_Get_BY_NAME_SUCCESS:
            return { ...state, CityName: action.payload[0], loading: false, error: null };      
        case constants.Cities_Get_COUNTRY_CODE_SUCCESS:
            return { ...state, CountryCode: action.payload, loading: false, error: null };      
        default:
            return { ...state };
    }
};

export default Reducer;
