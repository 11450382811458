import { all, call, fork, put, takeEvery ,take} from 'redux-saga/effects';
import * as constants from './constant'
import * as action from './action'
import { fetchJSON, optionPost } from '../../ApiUtils/apiUtils';
import { handleError } from '../../ApiUtils/handleError';
import { listUrl } from '../../ApiUtils/listUrl';

function* AuthRegister({ payload: { body } }) {    
    try {
        const response = yield call(fetchJSON, listUrl.authRegister, optionPost(body));  
                
        yield put(action.AuthRegisterSuccess(response));
    } catch (error) {
        let message = handleError(error);
        yield put(action.AuthRegisterFailed(message));
    }
}

function* AuthVerfiy({ payload: { body } }) {
    try {
        const response = yield call(fetchJSON, listUrl.authVerify, optionPost(body));  
        window.localStorage.setItem("strayers_token", response.data.access_token)          
        yield put(action.AuthVerfiySuccess(response));
    } catch (error) {
        let message = handleError(error);
        yield put(action.AuthVerfiyFailed(message));
    }
}
export function* watchAuthRegister() {
    yield takeEvery(constants.Auth_Register, AuthRegister);
}
export function* watchAuthVerfiy() {
    yield takeEvery(constants.Auth_Verfiy, AuthVerfiy);
}
function* Saga() {
    yield all([
        fork(watchAuthVerfiy),
        fork(watchAuthRegister),
    ]);
}
export default Saga;