import * as constants from './constant'
const INIT_STATE = {
    loading: false,
};
const Reducer = (state = INIT_STATE, action) => {
    switch (action.type) {        
        case constants.Message_Send:
        case constants.MESSAGE_LIST_BY_CHAT_ID:
            return { ...state,  loading: true,sended:false };
        case constants.Message_Send_FAILED:
        case constants.MESSAGE_LIST_BY_CHAT_ID_FAILED:
            return { ...state, loading: false, error: action.payload };       
        case constants.Message_Send_SUCCESS:
            return { ...state, sended: true, loading: false, error: null };           
        case constants.MESSAGE_LIST_BY_CHAT_ID_SUCCESS:
           //if(!state.listMessage)
           // return { ...state, listMessage:{...state.listMessage,...action.payload.data} ,listMessageCount:action.payload.count, loading: false, error: null };           
            return { ...state, listMessage:action.payload.data ,listMessageCount:action.payload.count, loading: false, error: null };           
       case constants.ADD_MESSAGE_TO_LIST:
        return {...state,listMessage: [...state.listMessage, action.payload]}
         //  return { ...state, listMessage:[...action.payload.list,action.payload.item] , loading: false, error: null };           
        default:
            return { ...state };
    }
};
export default Reducer;
