import * as constants from './constant'

export const  Categories_Get= (body) => ({
    type: constants.Categories_Get,
    payload: { body },
});

export const Categories_GetSuccess = (user) => ({
    type: constants.Categories_Get_SUCCESS,
    payload: user,
});

export const Categories_GetFailed = (error) => ({
    type: constants.Categories_Get_FAILED,
    payload: error,
});

