export const Cities_Get = 'Cities_Get';
export const Cities_Get_SUCCESS = 'Cities_Get_SUCCESS';
export const Cities_Get_FAILED = 'Cities_Get_FAILED';

export const Cities_Get_BY_NAME = 'Cities_Get_BY_NAME';
export const Cities_Get_BY_NAME_SUCCESS = 'Cities_Get_BY_NAME_SUCCESS';
export const Cities_Get_BY_NAME_FAILED = 'Cities_Get_BY_NAME_FAILED';


export const Cities_ALL_LIST_WITH_PARENT = 'Cities_ALL_LIST_WITH_PARENT';
export const Cities_ALL_LIST_WITH_PARENT_SUCCESS = 'Cities_ALL_LIST_WITH_PARENT_SUCCESS';
export const Cities_ALL_LIST_WITH_PARENT_FAILED = 'Cities_ALL_LIST_WITH_PARENT_FAILED';

export const Cities_Get_COUNTRY_CODE = 'Cities_Get_COUNTRY_CODE';
export const Cities_Get_COUNTRY_CODE_SUCCESS = 'Cities_Get_COUNTRY_CODE_SUCCESS';
export const Cities_Get_COUNTRY_CODE_FAILED = 'Cities_Get_COUNTRY_CODE_FAILED';
