import React, { useEffect, useState } from 'react';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import { allFlattenRoutes as Routers } from './index';
import { baseRoute } from './baseRoute'
import ErrorBoundaryComponent from './ErrorBoundary'
import { ErrorBoundary } from "react-error-boundary";
import { getLayout } from '../layouts/LayoutContainers';
import { AwsRum } from 'aws-rum-web';
const Routes = (props) => {
    const Layout = getLayout();
    //const [routes, setRoutes] = useState(Routers)    
    // const getRoutes = () => {
    //    console.log(Routers)
    //         const r = [];
    //         Routers.forEach((item, i) => {           
    //             r.push(item);
    //         })            
    //         setRoutes(r)
        
    // }
    // useEffect(() => { getRoutes(); }, [])
    const LogSave = (error) => {
        AwsRum.recordError(error);
        console.log(error);
    };
    return <BrowserRouter>
        <ErrorBoundary FallbackComponent={ErrorBoundaryComponent} onError={(e) => LogSave(e)}>
            <Layout {...props}>
                <Switch>
                    {Routers.map((route, index) => {
                        return (<Route key={`B${index}`} exact={route.exact} path={route.path} component={route.component} />)
                    })}
                    {/* {!isUserAuthenticated() ? <Redirect to={{ pathname: '/index' }} /> : null} */}
                    {/* {routes && routes.map((route, index) => {
                        return (
                            <route.route key={index} path={route.path}  exact={route.exact} component={route.component}></route.route>
                        );
                    })} */}
                </Switch>
            </Layout>
        </ErrorBoundary>
    </BrowserRouter>
}
export default Routes
