import React from 'react';
import { PrivateRoute } from './PrivateRoute'
import { flattenRoutes } from './flattenRoutes'
const IndexComponent = React.lazy(() => import('../pages/index'));
const NologinComponent = React.lazy(() => import('../pages/nologin'));
const LoginComponent = React.lazy(() => import('../pages/Login'));
const SignupComponent = React.lazy(() => import('../pages/Signup'));
const ChatsComponent = React.lazy(() => import('../pages/Chat/chats'));
const ChatComponent = React.lazy(() => import('../pages/Chat/chat'));
const testWebsocketComponent = React.lazy(() => import('../helpers/WebSocket'));


const rootRoute = { path: '/', exact: true, component: IndexComponent, route: PrivateRoute };
const LoginComponentRoute = { path: '/login', component: LoginComponent }
const SignupComponentRoute = { path: '/signup', component: SignupComponent }
const ChatsComponentRoute = { path: '/chats', component: ChatsComponent }
const ChatComponentRoute = { path: '/chat/:id', component: ChatComponent }
const NologinComponentRoute = { path: '/:city', component: NologinComponent }
const testWebsocketComponentRoute = { path: '/testweb', component: testWebsocketComponent }


const Superuser = [LoginComponentRoute, SignupComponentRoute, ChatsComponentRoute, ChatComponentRoute, NologinComponentRoute,testWebsocketComponentRoute]
// All routes
const allRoutes = [rootRoute, ...Superuser];
const authProtectedRoutes = [...Superuser];
const allFlattenRoutes = flattenRoutes(allRoutes);
export { allRoutes, authProtectedRoutes, allFlattenRoutes };