import { all, call, fork, put, takeEvery ,take} from 'redux-saga/effects';
import * as constants from './constant'
import * as action from './action'
import { fetchJSON, optionGet } from '../../ApiUtils/apiUtils';
import { handleError } from '../../ApiUtils/handleError';
import { listUrl } from '../../ApiUtils/listUrl';
import CountryCode from '../../assets/country-code.json'

function* GetCountryCode({ payload: { body } }) {
    try {
      
        yield put(action.CitiesGetCountryCodeSuccess(CountryCode));
    } catch (error) {
        let message = handleError(error);
        yield put(action.CitiesGetCountryCodeFailed(message));
    }
}
function* CitiesGetAllWithParent({ payload: { body } }) {
    try {
        const response = yield call(fetchJSON, listUrl.CitiesGetListCity, optionGet(body)); 
        yield put(action.Cities_GetAllWithParentSuccess(response));
    } catch (error) {
        let message = handleError(error);
        yield put(action.Cities_GetAllWithParentFailed(message));
    }
}
function* CitiesGetAll({ payload: { body } }) {
    try {
        const response = yield call(fetchJSON, listUrl.CitiesGet, optionGet(body));        
        yield put(action.Cities_GetSuccess(response));
    } catch (error) {
        let message = handleError(error);
        yield put(action.Cities_GetFailed(message));
    }
}
function* CitiesGetByName({ payload: { body } }) {
    try {
        const response = yield call(fetchJSON, listUrl.CitiesGetByName, optionGet(body));              
        yield put(action.Cities_GetByNameSuccess(response));
    } catch (error) {
        let message = handleError(error);
        yield put(action.Cities_GetByNameFailed(message));
    }
}


export function* watchGetCountryCode() {
    yield takeEvery(constants.Cities_Get_COUNTRY_CODE, GetCountryCode);
}
export function* watchCitiesGetAll() {
    yield takeEvery(constants.Cities_Get, CitiesGetAll);
}
export function* watchCitiesGetByName() {
    yield takeEvery(constants.Cities_Get_BY_NAME, CitiesGetByName);
}
export function* watchCitiesGetAllWithParent() {
    yield takeEvery(constants.Cities_ALL_LIST_WITH_PARENT, CitiesGetAllWithParent);
}
function* Saga() {
    yield all([
        fork(watchGetCountryCode),
        fork(watchCitiesGetAllWithParent),
        fork(watchCitiesGetByName),
        fork(watchCitiesGetAll),
    ]);
}
export default Saga;