export const Users_Get_NO_LOGIN = 'Users_Get_NO_LOGIN';
export const Users_Get_NO_LOGIN_SUCCESS = 'Users_Get_NO_LOGIN_SUCCESS';
export const Users_Get_NO_LOGIN_FAILED = 'Users_Get_NO_LOGIN_FAILED';


export const Users_Get_LOGIN = 'Users_Get_LOGIN';
export const Users_Get_LOGIN_SUCCESS = 'Users_Get_LOGIN_SUCCESS';
export const Users_Get_LOGIN_FAILED = 'Users_Get_LOGIN_FAILED';


export const Users_UPDATE_INFO = 'Users_UPDATE_INFO';
export const Users_UPDATE_INFO_SUCCESS = 'Users_UPDATE_INFO_SUCCESS';
export const Users_UPDATE_INFO_FAILED = 'Users_UPDATE_INFO_FAILED';


export const Users_CITY_UPDATE_INFO = 'Users_CITY_UPDATE_INFO';
export const Users_CITY_UPDATE_INFO_SUCCESS = 'Users_CITY_UPDATE_INFO_SUCCESS';
export const Users_CITY_UPDATE_INFO_FAILED = 'Users_CITY_UPDATE_INFO_FAILED';

export const Users_Get = 'Users_Get';
export const Users_Get_SUCCESS = 'Users_Get_SUCCESS';
export const Users_Get_FAILED = 'Users_Get_FAILED';

export const Users_NAME_UPDATE_INFO = 'Users_NAME_UPDATE_INFO';
export const Users_NAME_UPDATE_INFO_SUCCESS = 'Users_NAME_UPDATE_INFO_SUCCESS';
export const Users_NAME_UPDATE_INFO_FAILED = 'Users_NAME_UPDATE_INFO_FAILED';

export const Users_CATEGORIES_UPDATE_INFO = 'Users_CATEGORIES_UPDATE_INFO';
export const Users_CATEGORIES_UPDATE_INFO_SUCCESS = 'Users_CATEGORIES_UPDATE_INFO_SUCCESS';
export const Users_CATEGORIES_UPDATE_INFO_FAILED = 'Users_CATEGORIES_UPDATE_INFO_FAILED';
