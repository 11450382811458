import { all, call, fork, put, takeEvery ,take} from 'redux-saga/effects';
import * as constants from './constant'
import * as action from './action'
import { fetchJSON, optionGet, optionPatch } from '../../ApiUtils/apiUtils';
import { handleError } from '../../ApiUtils/handleError';
import { listUrl } from '../../ApiUtils/listUrl';
function* UsersNameUpdateInfo({ payload: { body } }) {
    try {
        const response = yield call(fetchJSON, `${listUrl.usersUpdateName}`, optionPatch(body));
        window.localStorage.setItem("strayers_token", response.data.access_token)              
        yield put(action.UsersNameUpdateInfoSuccess(response));
    } catch (error) {
        let message = handleError(error);
        yield put(action.UsersNameUpdateInfoFailed(message));
    }
}
function* UsersCategoriesUpdateInfo({ payload: { body } }) {
    try {
        const response = yield call(fetchJSON, listUrl.usersUpdateCategories, optionPatch(body));
        window.localStorage.setItem("strayers_token", response.data.access_token)              
        yield put(action.UsersCategoriesUpdateInfoSuccess(response));
    } catch (error) {
        let message = handleError(error);
        yield put(action.UsersCategoriesUpdateInfoFailed(message));
    }
}
function* UsersUpdateInfo({ payload: { body } }) {
    try {
        const response = yield call(fetchJSON, `${listUrl.usersUpdate}`, optionPatch(body));     
        window.localStorage.setItem("strayers_token", response.data.access_token)         
        yield put(action.UsersUpdateInfoSuccess(response));
    } catch (error) {
        let message = handleError(error);
        yield put(action.UsersUpdateInfoFailed(message));
    }
}
function* UsersCityUpdateInfo({ payload: { body } }) {
    try {
        const response = yield call(fetchJSON,listUrl.usersUpdateCity, optionPatch(body));
        window.localStorage.setItem("strayers_token", response.data.access_token)              
        yield put(action.UsersCityUpdateInfoSuccess(response));
    } catch (error) {
        let message = handleError(error);
        yield put(action.UsersCityUpdateInfoFailed(message));
    }
}
function* UsersGetNoLogin({ payload: { body } }) {
    try {
        const response = yield call(fetchJSON, listUrl.usersGetNologin, optionGet(body));              
        yield put(action.UsersGetNOLOGINSuccess(response));
    } catch (error) {
        let message = handleError(error);
        yield put(action.UsersGetNOLOGINFailed(message));
    }
}
function* UsersGetLogin({ payload: { body } }) {
    try {
        const response = yield call(fetchJSON, listUrl.usersListlogin, optionGet(body));              
        yield put(action.UsersGetLOGINSuccess(response));
    } catch (error) {
        let message = handleError(error);
        yield put(action.UsersGetLOGINFailed(message));
    }
}
function* UsersGet({ payload: { body } }) {
    try {
        const response = yield call(fetchJSON, listUrl.usersGet, optionGet(body));        
        yield put(action.Users_GetSuccess(response));
    } catch (error) {
        let message = handleError(error);
        yield put(action.Users_GetFailed(message));
    }
}
export function* watchUsersNameUpdateInfo() {
    yield takeEvery(constants.Users_NAME_UPDATE_INFO, UsersNameUpdateInfo);
}
export function* watchUsersCategoriesUpdateInfo() {
    yield takeEvery(constants.Users_CATEGORIES_UPDATE_INFO, UsersCategoriesUpdateInfo);
}
export function* watchUsersUpdateInfo() {
    yield takeEvery(constants.Users_UPDATE_INFO, UsersUpdateInfo);
}
export function* watchUsersCityUpdateInfo() {
    yield takeEvery(constants.Users_CITY_UPDATE_INFO, UsersCityUpdateInfo);
}
export function* watchUsersGet() {
    yield takeEvery(constants.Users_Get, UsersGet);
}
export function* watchUsersGetNoLogin() {
    yield takeEvery(constants.Users_Get_NO_LOGIN, UsersGetNoLogin);
}
export function* watchUsersGetLogin() {
    yield takeEvery(constants.Users_Get_LOGIN, UsersGetLogin);
}
function* Saga() {
    yield all([
        fork(watchUsersNameUpdateInfo),
        fork(watchUsersCategoriesUpdateInfo),
        fork(watchUsersCityUpdateInfo),
        fork(watchUsersGet),
        fork(watchUsersUpdateInfo),
        fork(watchUsersGetNoLogin),
        fork(watchUsersGetLogin),
    ]);
}
export default Saga;