const cities='/cities'
const users='/users'
const auth='/auth'
const categories='/categories'
const chats='/chats'
const reportUsers='/reportUsers'
const messages='/messages'
export const listUrl = {
    CitiesGet: `${cities}`,
    CitiesGetByName: `${cities}/search`,
    CitiesGetListCity: `${cities}`,
    usersGetNologin: `${users}/nologin`,
    usersListlogin: `${users}/list`,
    usersUpdate: `${users}/update`,
    usersUpdateCity: `${users}/updateCity`,
    usersUpdateCategories: `${users}/updateCategories`,
    usersUpdateName: `${users}/updateName`,
    usersGet: `${users}/Get`,
    authRegister: `${auth}/register`,
    authVerify: `${auth}/verify`,
    categories: `${categories}`,
    chatsChatList: `${chats}/list`,
    chatsGet: `${chats}/GetById`,
    chatsDelete: `${chats}/Delete`,   
    ChatExistence: `${chats}/ChatExistence`,
    ChatBlockUser: `${chats}/BlockUser`, 
    reportUsers: `${reportUsers}`,
    messages: `${messages}/Add`,   
    messagesListByChatId: `${messages}/listByChatId`,   
    messageslistByChatIdPage: `${messages}/listByChatIdPage`,   
}