import { all, call, fork, put, takeEvery ,take} from 'redux-saga/effects';
import * as constants from './constant'
import * as action from './action'
import { fetchJSON, optionGet } from '../../ApiUtils/apiUtils';
import { handleError } from '../../ApiUtils/handleError';
import { listUrl } from '../../ApiUtils/listUrl';
function* CategoriesGetAll({ payload: { body } }) {
    try {
        const response = yield call(fetchJSON, listUrl.categories, optionGet(body));        
        yield put(action.Categories_GetSuccess(response));
    } catch (error) {
        let message = handleError(error);
        yield put(action.Categories_GetFailed(message));
    }
}
export function* watchCategoriesGetAll() {
    yield takeEvery(constants.Categories_Get, CategoriesGetAll);
}
function* Saga() {
    yield all([
        fork(watchCategoriesGetAll),
    ]);
}
export default Saga;