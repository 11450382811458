import * as constants from './constant'

export const  Message_Send= (body) => ({
    type: constants.Message_Send,
    payload: { body },
});

export const Message_SendSuccess = (user) => ({
    type: constants.Message_Send_SUCCESS,
    payload: user,
});

export const Message_SendFailed = (error) => ({
    type: constants.Message_Send_FAILED,
    payload: error,
});


export const  Message_ListByChatId= (body) => ({
    type: constants.MESSAGE_LIST_BY_CHAT_ID,
    payload: { body },
});

export const Message_ListByChatIdSuccess = (user) => ({
    type: constants.MESSAGE_LIST_BY_CHAT_ID_SUCCESS,
    payload: user,
});

export const Message_ListByChatIdFailed = (error) => ({
    type: constants.MESSAGE_LIST_BY_CHAT_ID_FAILED,
    payload: error,
});
export const Message_AddMessage= (message) => ({
    type: constants.ADD_MESSAGE_TO_LIST,
    payload: message,
});
