import { combineReducers } from 'redux';
import Cities from './Cities/reducers';
import Users from './Users/reducers';
import Auth from './Auth/reducers';
import Categories from './Categories/reducers';
import Chats from './Chats/reducers';
import ReportUsers from './ReportUsers/reducers';
import Messages from './Messages/reducers';

export default combineReducers({
    Cities,Users,Auth,Categories,Chats,ReportUsers,Messages
});