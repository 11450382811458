import { jwtDecode } from "jwt-decode";
export const decodeToken = (token) => {
    try {
        const jwtToken = token;
        if (!jwtToken) { return null; }
        return jwtDecode(jwtToken);
    }
    catch {
        return null;
    }
}

export const isUserAuthenticated = () => {
    try {
        const jwtToken = getLoggedInUser();
        if (!jwtToken) { return false; }
        return true;
    }
    catch {
        return false;
    }
};
export const getLoggedInUser = () => {
    const user = localStorage.getItem("strayers_token");
    return user || null;
};
export const loading = () => {
    return (<div className="text-center"></div>)
}
export const getCityNameByIp = () => {

    let name = ''
    fetch('http://ip-api.com/json/?fields=61439')
        .then(res => res.json())
        .then(res => {
            console.log(res.city)
            return cityName(res.city)
        });

}
export const cityName = (city) => {
    switch (city) {
        case "germany":
        case "Germany":
            return "berlin"
        case "sweden":
        case "Sweden":
            return "stockholm"
        case "Portugal":
        case "portugal":
            return "lisbon"
        default:
            return "AllCities"
    }
}
export const validatePhoneNumber = (input_str) => {
    return true
    // const re=  new RegExp('^[a-zA-Z0-9._:$!%-]+@[a-zA-Z0-9.-]+.[a-zA-Z]$');
    // const re=  new RegExp('^(0|[1-9]\d*)$');
    // var re = /^(\\+(([0-9]){1,2})[-.])?((((([0-9]){2,3})[-.]){1,2}([0-9]{4,10}))|([0-9]{10}))$/;
    // var re = /^(0|[1-9]\d*)$/;
    //console.log(re.test(input_str))
    // return re.test(input_str);
}
export function isNumber(evt) {
    evt = (evt) ? evt : window.event;
    var charCode = (evt.which) ? evt.which : evt.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        return false;
    }
    return true;
}