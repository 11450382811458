import { jwtDecode } from "jwt-decode";
import{getLoggedInUser} from './utils'
export const getActiveToken = () => {
    return 'Bearer ' + getLoggedInUser();
}
export const getValueToken = (paramter) => {
    try {
        const jwtToken = getLoggedInUser();
        if (!jwtToken) { return null; }
        const token = jwtDecode(jwtToken);
        return token[paramter];
    }
    catch {
        return null;
    }
}
export const getUserId = () => {
    try {
        const jwtToken = getLoggedInUser();
        if (!jwtToken) { return null; }
        const token = jwtDecode(jwtToken);
        return token.id;
    }
    catch {
        return null;
    }
}
export const HasData = (access_token) => {
    try {
        const jwtToken = access_token;
        if (!jwtToken) { return false; }
        const token = jwtDecode(jwtToken);
        const age = token.age;
        const name = token.name;
        const des = token.des;
        const cat = token.cat;
        if(age!==null&&name !==null && des!==null && cat!==null){
            return true;
        }
        
        return false;
    }
    catch {
        return false;
    }
}