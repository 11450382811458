import * as constants from './constant'

export const  UsersGetNOLOGIN= (body) => ({
    type: constants.Users_Get_NO_LOGIN,
    payload: { body },
});

export const UsersGetNOLOGINSuccess = (user) => ({
    type: constants.Users_Get_NO_LOGIN_SUCCESS,
    payload: user,
});

export const UsersGetNOLOGINFailed = (error) => ({
    type: constants.Users_Get_NO_LOGIN_FAILED,
    payload: error,
});
export const  UsersGetLOGIN= (body) => ({
    type: constants.Users_Get_LOGIN,
    payload: { body },
});

export const UsersGetLOGINSuccess = (user) => ({
    type: constants.Users_Get_LOGIN_SUCCESS,
    payload: user,
});

export const UsersGetLOGINFailed = (error) => ({
    type: constants.Users_Get_LOGIN_FAILED,
    payload: error,
});


export const  UsersUpdateInfo= (body) => ({
    type: constants.Users_UPDATE_INFO,
    payload: { body },
});

export const UsersUpdateInfoSuccess = (user) => ({
    type: constants.Users_UPDATE_INFO_SUCCESS,
    payload: user,
});

export const UsersUpdateInfoFailed = (error) => ({
    type: constants.Users_UPDATE_INFO_FAILED,
    payload: error,
});
export const  UsersCityUpdateInfo= (body) => ({
    type: constants.Users_CITY_UPDATE_INFO,
    payload: { body },
});

export const UsersCityUpdateInfoSuccess = (user) => ({
    type: constants.Users_CITY_UPDATE_INFO_SUCCESS,
    payload: user,
});

export const UsersCityUpdateInfoFailed = (error) => ({
    type: constants.Users_CITY_UPDATE_INFO_FAILED,
    payload: error,
});


export const  Users_Get= (body) => ({
    type: constants.Users_Get,
    payload: { body },
});

export const Users_GetSuccess = (user) => ({
    type: constants.Users_Get_SUCCESS,
    payload: user,
});

export const Users_GetFailed = (error) => ({
    type: constants.Users_Get_FAILED,
    payload: error,
});

export const  UsersNameUpdateInfo= (body) => ({
    type: constants.Users_NAME_UPDATE_INFO,
    payload: { body },
});

export const UsersNameUpdateInfoSuccess = (user) => ({
    type: constants.Users_NAME_UPDATE_INFO_SUCCESS,
    payload: user,
});

export const UsersNameUpdateInfoFailed = (error) => ({
    type: constants.Users_NAME_UPDATE_INFO_FAILED,
    payload: error,
});

export const  UsersCategoriesUpdateInfo= (body) => ({
    type: constants.Users_CATEGORIES_UPDATE_INFO,
    payload: { body },
});

export const UsersCategoriesUpdateInfoSuccess = (user) => ({
    type: constants.Users_CATEGORIES_UPDATE_INFO_SUCCESS,
    payload: user,
});

export const UsersCategoriesUpdateInfoFailed = (error) => ({
    type: constants.Users_CATEGORIES_UPDATE_INFO_FAILED,
    payload: error,
});