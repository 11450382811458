import * as constants from './constant'

const INIT_STATE = {
    loading: false,
};

const Reducer = (state = INIT_STATE, action) => {
    switch (action.type) {        
        case constants.Users_Get_NO_LOGIN:
        case constants.Users_Get_LOGIN:
        case constants.Users_UPDATE_INFO:
        case constants.Users_Get:
        case constants.Users_CITY_UPDATE_INFO:
        case constants.Users_NAME_UPDATE_INFO:
        case constants.Users_CATEGORIES_UPDATE_INFO:
            return { ...state,  loading: true,updateCity:false,updateName:false,updateCategories:false };
        case constants.Users_Get_LOGIN_FAILED:
        case constants.Users_Get_NO_LOGIN_FAILED:
        case constants.Users_UPDATE_INFO_FAILED:
        case constants.Users_Get_FAILED:
        case constants.Users_CITY_UPDATE_INFO_FAILED:
            case constants.Users_NAME_UPDATE_INFO_FAILED:
        case constants.Users_CATEGORIES_UPDATE_INFO_FAILED:
            return { ...state, loading: false, error: action.payload };       
        case constants.Users_CITY_UPDATE_INFO_SUCCESS:
            return { ...state, updateCity: true, loading: false, error: null };      
        case constants.Users_NAME_UPDATE_INFO_SUCCESS:
            return { ...state, updateName: true, loading: false, error: null };      
        case constants.Users_CATEGORIES_UPDATE_INFO_SUCCESS:
            return { ...state, updateCategories: true, loading: false, error: null };      
        case constants.Users_Get_NO_LOGIN_SUCCESS:
            return { ...state, ListUserNologin: action.payload, loading: false, error: null };      
        case constants.Users_Get_LOGIN_SUCCESS:
            return { ...state, ListUserlogin: action.payload, loading: false, error: null };      
        case constants.Users_Get_SUCCESS:
            return { ...state, InfoUser: action.payload, loading: false, error: null };      
        case constants.Users_UPDATE_INFO_SUCCESS:
            return { ...state, IsUpdate: action.payload, loading: false, error: null };      
        default:
            return { ...state };
    }
};

export default Reducer;
