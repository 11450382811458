import React, { useEffect, useState } from 'react'
import './assets/scss/them.scss'
import Routes from './routes/Routes';
import ModalPwa from './pages/Modal/InstallPwa'
const App = () => {
    const [openModal, setOpenModal] = useState(false)
    const [installApp, setInstallApp] = useState(undefined)
    const [deferredPrompt, setDeferredPrompt] = useState(undefined)
    useEffect(() => {
        window.addEventListener('beforeinstallprompt', (e) => {
            e.preventDefault();
            setDeferredPrompt(e)
            const InstallStorage = localStorage.getItem('StrayersInstallApp')
            if (e !== null && installApp !== false && InstallStorage !== 'False') {
                setOpenModal(true)
            }else {
                setOpenModal(false)
            }
        })
        return () => {
            window.removeEventListener('beforeinstallprompt', () => { })
        }
    }, [installApp])
    const InstallPwa = () => {
        setOpenModal(false)
        deferredPrompt.prompt();
        setDeferredPrompt(null)
        setInstallApp(true)
        localStorage.removeItem('StrayersInstallApp')
    }
    const CancelInstall = (e) => {
        setOpenModal(e)
        setInstallApp(e)
        window.localStorage.setItem('StrayersInstallApp', 'False')
    }
    return (
        <React.Suspense fallback={<></>}>
            <Routes />
            <ModalPwa openModal={openModal} InstallPwa={() => { InstallPwa() }} setOpenModal={(e) => { CancelInstall(e) }} />
        </React.Suspense>);
}
export default App;
