import * as constants from './constant'
const INIT_STATE = {
    loading: false,
};
const Reducer = (state = INIT_STATE, action) => {
    switch (action.type) {        
        case constants.Categories_Get:
            return { ...state,  loading: true };
        case constants.Categories_Get_FAILED:
            return { ...state, loading: false, error: action.payload };       
        case constants.Categories_Get_SUCCESS:
            return { ...state, ListCategories: action.payload.data.items, loading: false, error: null };           
        default:
            return { ...state };
    }
};
export default Reducer;
