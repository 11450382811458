import React from 'react';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
const InstallPwa = (props) => {
    return (<Modal centered isOpen={props.openModal} className='h370' size='md'>
        <ModalHeader className='border-none flex-row-reverse'>
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={()=>{props.setOpenModal(false)}}></button>
        </ModalHeader>
        <ModalBody>
            <div className="row mx-0 modal-form">
                <div className="col-md-6 col-sm-7 col-10">
                    <h3 className="mb-0">For Installation APP Just Click Install</h3>
                </div>
            </div>

        </ModalBody>
        <ModalFooter className='d-flex justify-content-center border-none pb-5'>
        <div className="start-link go-link modal-footer border-none">
                <span className='hw100 cursor-pointer' onClick={() => { props.InstallPwa() }} >Install</span>
            </div>
            <div className="start-link px-0 mx-1">
                <span className='hw100 cursor-pointer' onClick={() => { props.setOpenModal(false) }} >Cancel</span>
            </div>
        </ModalFooter>
    </Modal>)
}
export default InstallPwa; 