import * as constants from './constant'

export const  Cities_Get= (body) => ({
    type: constants.Cities_Get,
    payload: { body },
});

export const Cities_GetSuccess = (user) => ({
    type: constants.Cities_Get_SUCCESS,
    payload: user,
});

export const Cities_GetFailed = (error) => ({
    type: constants.Cities_Get_FAILED,
    payload: error,
});


export const  Cities_GetByName= (body) => ({
    type: constants.Cities_Get_BY_NAME,
    payload: { body },
});

export const Cities_GetByNameSuccess = (res) => ({
    type: constants.Cities_Get_BY_NAME_SUCCESS,
    payload: res,
});

export const Cities_GetByNameFailed = (error) => ({
    type: constants.Cities_Get_BY_NAME_FAILED,
    payload: error,
});


export const  Cities_GetAllWithParent= (body) => ({
    type: constants.Cities_ALL_LIST_WITH_PARENT,
    payload: { body },
});

export const Cities_GetAllWithParentSuccess = (res) => ({
    type: constants.Cities_ALL_LIST_WITH_PARENT_SUCCESS,
    payload: res,
});

export const Cities_GetAllWithParentFailed = (error) => ({
    type: constants.Cities_ALL_LIST_WITH_PARENT_FAILED,
    payload: error,
});

export const  CitiesGetCountryCode= (body) => ({
    type: constants.Cities_Get_COUNTRY_CODE,
    payload: { body },
});

export const CitiesGetCountryCodeSuccess = (user) => ({
    type: constants.Cities_Get_COUNTRY_CODE_SUCCESS,
    payload: user,
});

export const CitiesGetCountryCodeFailed = (error) => ({
    type: constants.Cities_Get_COUNTRY_CODE_FAILED,
    payload: error,
});
